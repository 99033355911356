import React, { useState } from 'react';

const Step4CodeGeneration = ({ generatedCode, setGeneratedCode, testOutput, setTestOutput, onNext, onPrev }) => {
  const [prompt, setPrompt] = useState('');

  async function handleGenerateCode() {
    if (!prompt) return;
    const res = await fetch('/generate-code', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ prompt })
    });
    const data = await res.json();
    if (data.code) {
      setGeneratedCode(data.code);
    }
  }

  async function testCode() {
    if (!generatedCode) return;
    const res = await fetch('/api/test-code', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ code: generatedCode })
    });
    const data = await res.json();
    setTestOutput(data.output || data.errors || '');
  }

  async function deployCode() {
    if (!generatedCode) return;
    const res = await fetch('/api/deploy', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ code: generatedCode })
    });
    const data = await res.json();
    if (data.status === 'deployed') {
      onNext();
    }
  }

  return (
    <div>
      <h2 className="font-bold text-xl">Generate & Deploy Your Code</h2>
      <textarea
        className="border p-2 w-full mt-2"
        value={prompt}
        onChange={e => setPrompt(e.target.value)}
        placeholder="Enter prompt for code generation..."
      />
      <button
        className="mt-2 p-2 bg-blue-500 text-white"
        onClick={handleGenerateCode}
      >
        Generate Code
      </button>
      {generatedCode && (
        <pre className="mt-4 p-2 border bg-gray-100 whitespace-pre-wrap">
          {generatedCode}
        </pre>
      )}

      {generatedCode && (
        <>
          <button
            className="mt-2 p-2 bg-green-500 text-white"
            onClick={testCode}
          >
            Test Code
          </button>
          {testOutput && (
            <pre className="mt-4 border p-2">
              {testOutput}
            </pre>
          )}

          <button
            className="mt-2 p-2 bg-purple-500 text-white"
            onClick={deployCode}
          >
            Deploy Code
          </button>
        </>
      )}
      
      <button className="mt-4 p-2 bg-gray-300" onClick={onPrev}>Back</button>
    </div>
  );
};

export default Step4CodeGeneration;
