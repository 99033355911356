import React, { useState, useEffect } from 'react';

const PhoneInput = ({ phone, setPhone }) => {
  const [countryCode, setCountryCode] = useState('+1');
  const [localNumber, setLocalNumber] = useState('');

  const countryOptions = [
    { code: '+1', label: 'United States (+1)' },
    { code: '+44', label: 'United Kingdom (+44)' },
    { code: '+61', label: 'Australia (+61)' },
  ];

  useEffect(() => {
    const cleanedNumber = localNumber.replace(/[^\d]/g, '');
    const formatted = `${countryCode}.${cleanedNumber}`;
    setPhone(formatted);
  }, [countryCode, localNumber, setPhone]);

  return (
    <div className="mt-2">
      <label className="block mb-1 font-bold">Phone Number</label>
      <div className="flex items-center">
        <select
          className="border p-1 mr-2"
          value={countryCode}
          onChange={e => setCountryCode(e.target.value)}
        >
          {countryOptions.map(opt => (
            <option key={opt.code} value={opt.code}>
              {opt.label}
            </option>
          ))}
        </select>
        <input
          type="text"
          className="border p-1"
          placeholder="Local number"
          value={localNumber}
          onChange={e => setLocalNumber(e.target.value)}
        />
      </div>
      <p className="text-sm text-gray-600 mt-1">Format: {phone}</p>
    </div>
  );
};

export default PhoneInput;
