import React, { useState } from 'react';
import NavBar from '../components/NavBar';
import Sidebar from '../components/Sidebar';
import CodeManager from '../components/CodeManager';
import BrandingForm from '../components/BrandingForm';
import PageManager from '../components/PageManager';
import Infrastructure from '../components/Infrastructure';
import SiteStructure from '../components/SiteStructure';

const Dashboard = () => {
  const [activeSection, setActiveSection] = useState('infrastructure');

  return (
    <div className="h-screen flex flex-col">
      <NavBar />
      <div className="flex flex-1 overflow-hidden">
        <Sidebar activeSection={activeSection} setActiveSection={setActiveSection} />
        <div className="flex-1 overflow-auto p-4 bg-gray-50">
          {activeSection === 'infrastructure' && <Infrastructure />}
          {activeSection === 'site-structure' && <SiteStructure />}
          {activeSection === 'code' && <CodeManager />}
          {activeSection === 'pages' && <PageManager />}
          {activeSection === 'branding' && <BrandingForm />}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
