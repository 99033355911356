import React, { useState, useEffect, useMemo } from 'react';
import ReactFlow, { MiniMap, Controls, Background } from 'reactflow';
import 'reactflow/dist/style.css';

// A helper to generate nodes & edges from site structure data
function generateGraph(structure) {
  // We'll place pages on the left (x=100), frontend components in middle (x=400), backend files on right (x=700)
  // y positions will increment with each item for neat spacing.

  let yOffset = 50;
  const yStep = 80;

  const pageNodes = structure.pages.map((p, i) => ({
    id: `page-${p}`,
    type: 'input',
    data: { label: p },
    position: { x: 100, y: yOffset + i * yStep }
  }));

  const frontNodes = structure.frontendComponents.map((fc, i) => ({
    id: `frontend-${fc}`,
    data: { label: fc },
    position: { x: 400, y: yOffset + i * yStep }
  }));

  const backNodes = structure.backendFiles.map((bf, i) => ({
    id: `backend-${bf}`,
    data: { label: bf },
    position: { x: 700, y: yOffset + i * yStep }
  }));

  // Edges:
  // For simplicity, connect each page node to each frontend node, and each page node to each backend node
  // (You can refine the logic based on actual relationships.)
  const edges = [];
  pageNodes.forEach((pn) => {
    frontNodes.forEach((fn) => {
      edges.push({
        id: `edge-${pn.id}-${fn.id}`,
        source: pn.id,
        target: fn.id,
        animated: false,
      });
    });
    backNodes.forEach((bn) => {
      edges.push({
        id: `edge-${pn.id}-${bn.id}`,
        source: pn.id,
        target: bn.id,
        animated: false,
      });
    });
  });

  return { nodes: [...pageNodes, ...frontNodes, ...backNodes], edges };
}

const SiteStructure = () => {
  const [structure, setStructure] = useState(null);

  useEffect(() => {
    fetchSiteStructure();
  }, []);

  async function fetchSiteStructure() {
    const res = await fetch('/api/site-structure');
    if (res.ok) {
      const data = await res.json();
      setStructure(data);
    }
  }

  const { nodes, edges } = useMemo(() => {
    if (!structure) return { nodes: [], edges: [] };
    return generateGraph(structure);
  }, [structure]);

  if (!structure) {
    return <div>Loading site structure...</div>;
  }

  return (
    <div className="space-y-4 h-full">
      <h2 className="text-2xl font-bold">Site Structure</h2>
      <p className="text-gray-600">
        Visualize your SaaS's structure as you add pages, components, and backend code.  
        Below is an interactive graph. You can pan, zoom, and rearrange nodes. 
      </p>

      <div className="w-full h-[600px] bg-white border rounded overflow-hidden">
        <ReactFlow
          nodes={nodes}
          edges={edges}
          fitView
        >
          <MiniMap />
          <Controls />
          <Background />
        </ReactFlow>
      </div>

      <div className="bg-white border rounded p-4">
        <h3 className="font-bold mb-2">Details</h3>
        <div className="grid grid-cols-3 gap-4 text-sm">
          <div>
            <h4 className="font-bold">Pages</h4>
            <ul className="list-disc list-inside">
              {structure.pages.map(p => <li key={p}>{p}</li>)}
            </ul>
          </div>
          <div>
            <h4 className="font-bold">Frontend Components</h4>
            <ul className="list-disc list-inside">
              {structure.frontendComponents.map(fc => <li key={fc}>{fc}</li>)}
            </ul>
          </div>
          <div>
            <h4 className="font-bold">Backend Files</h4>
            <ul className="list-disc list-inside">
              {structure.backendFiles.map(bf => <li key={bf}>{bf}</li>)}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteStructure;
