import React, { useState } from 'react';

const PaymentForm = ({ domain, price, onPaymentSuccess }) => {
  const [loading, setLoading] = useState(false);

  async function handlePay() {
    setLoading(true);
    const res = await fetch('/api/create-checkout-session', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ domain, price })
    });
    const data = await res.json();
    setLoading(false);
    if (data.url) {
      window.location.href = data.url;
    } else {
      alert('Error creating checkout session: ' + data.error);
    }
  }

  return (
    <div className="mt-4">
      <h3 className="font-bold">Price: ${price}</h3>
      <button
        className="mt-2 p-2 bg-blue-500 text-white"
        onClick={handlePay}
        disabled={loading}
      >
        {loading ? 'Redirecting...' : 'Pay Now'}
      </button>
    </div>
  );
};

export default PaymentForm;
