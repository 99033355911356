import React, { useState } from 'react';
import FoundersFirstJourney from './FoundersFirstJourney';

const GamesPage = () => {
  const [selectedGame, setSelectedGame] = useState(null);
  
  const games = [
    {
      id: 'founders-journey',
      title: "Founder's First Journey",
      description: 'Pilot the Founder Mothership and destroy the BGF rock formation in this retro space shooter!',
      icon: '??'
    }
  ];

  if (selectedGame === 'founders-journey') {
    return <FoundersFirstJourney />;
  }

  return (
    <div className="min-h-screen bg-black p-8" 
      style={{
        backgroundImage: `
          linear-gradient(rgba(0, 255, 0, 0.05) 1px, transparent 1px),
          linear-gradient(90deg, rgba(0, 255, 0, 0.05) 1px, transparent 1px)
        `,
        backgroundSize: '20px 20px'
      }}>
      <div className="max-w-7xl mx-auto">
        <h1 className="text-4xl font-bold text-green-500 mb-8 font-mono">Dan's Distraction Arcade</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {games.map(game => (
            <div 
              key={game.id}
              className="border border-green-500 bg-black rounded-none overflow-hidden cursor-pointer transform hover:scale-105 transition-transform"
              onClick={() => setSelectedGame(game.id)}
            >
              <div className="h-48 flex items-center justify-center border-b border-green-500">
                <div className="text-6xl">{game.icon}</div>
              </div>
              <div className="p-4">
                <h2 className="text-xl font-bold text-green-500 mb-2 font-mono">{game.title}</h2>
                <p className="text-green-400 font-mono text-sm">{game.description}</p>
              </div>
              <div className="px-4 pb-4">
                <button className="w-full bg-green-500 text-black py-2 font-mono hover:bg-green-400 transition-colors">
                  PLAY NOW
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GamesPage;