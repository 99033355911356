import React from 'react';

const Sidebar = ({ activeSection, setActiveSection, sidebarVisible, setSidebarVisible }) => {
  if (!sidebarVisible) {
    // If sidebar is hidden, just show a button to reveal it
    return (
      <div className="w-8 bg-white border-r flex flex-col items-center justify-start">
        <button
          className="mt-2 text-gray-700"
          onClick={() => setSidebarVisible(true)}
          title="Show Sidebar"
        >
          ?
        </button>
      </div>
    );
  }

  const items = [
    { key: 'infrastructure', label: 'Infrastructure' },
    { key: 'site-structure', label: 'Site Structure' },
    { key: 'code', label: 'Code Manager' },
    { key: 'pages', label: 'Page Manager' },
    { key: 'branding', label: 'Branding' },
  ];

  return (
    <div className="w-64 bg-white border-r flex flex-col">
      <div className="p-4 border-b flex justify-between items-center">
        <span className="font-bold text-gray-700">Menu</span>
        <button
          className="text-gray-700"
          onClick={() => setSidebarVisible(false)}
          title="Hide Sidebar"
        >
          �
        </button>
      </div>
      <div className="flex-1 overflow-auto">
        {items.map(item => (
          <button
            key={item.key}
            onClick={() => setActiveSection(item.key)}
            className={`block w-full text-left px-4 py-2 hover:bg-gray-100 ${
              activeSection === item.key ? 'bg-blue-100 text-blue-700' : 'text-gray-700'
            }`}
          >
            {item.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
