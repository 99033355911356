import React, { useState, useEffect } from 'react';

const BrandingForm = () => {
  const [logoFile, setLogoFile] = useState(null);
  const [primaryColor, setPrimaryColor] = useState('#000000');
  const [secondaryColor, setSecondaryColor] = useState('#ffffff');
  const [livePreviewUrl, setLivePreviewUrl] = useState('https://www.yourdomain.com'); // Where brand changes are reflected.

  async function uploadLogo() {
    if (!logoFile) return;
    const formData = new FormData();
    formData.append('logo', logoFile);
    const res = await fetch('/api/upload-logo', {
      method: 'POST',
      body: formData
    });
    const data = await res.json();
    if (data.status === 'uploaded') {
      alert('Logo uploaded successfully!');
    } else {
      alert(`Failed to upload logo: ${data.error || 'Unknown error'}`);
    }
  }

  async function applyBranding() {
    const res = await fetch('/api/apply-branding', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ primaryColor, secondaryColor })
    });
    const data = await res.json();
    if (data.status === 'applied') {
      alert('Branding applied successfully!');
    } else {
      alert(`Failed to apply branding: ${data.error || 'Unknown error'}`);
    }
  }

  return (
    <div className="space-y-4">
      <h2 className="text-2xl font-bold">Branding</h2>
      <p className="text-gray-600">Upload your logo and set brand colors. Changes will reflect on your live site.</p>

      <div className="bg-white border rounded p-4 space-y-2">
        <h3 className="font-bold">Logo Upload</h3>
        <input
          type="file"
          className="mt-2"
          onChange={e => setLogoFile(e.target.files[0])}
        />
        <button className="mt-2 p-2 bg-blue-500 text-white" onClick={uploadLogo}>
          Upload Logo
        </button>
      </div>

      <div className="bg-white border rounded p-4 space-y-2">
        <h3 className="font-bold">Brand Colors</h3>
        <label className="block mb-2">
          Primary Color:
          <input
            type="color"
            value={primaryColor}
            onChange={e => setPrimaryColor(e.target.value)}
            className="ml-2"
          />
        </label>
        <label className="block mb-2">
          Secondary Color:
          <input
            type="color"
            value={secondaryColor}
            onChange={e => setSecondaryColor(e.target.value)}
            className="ml-2"
          />
        </label>
        <button className="mt-2 p-2 bg-green-500 text-white" onClick={applyBranding}>
          Apply Branding
        </button>
      </div>

      {/* Live Preview */}
      <div className="bg-white border rounded p-4">
        <h3 className="font-bold mb-2">Live Preview</h3>
        <p className="text-sm text-gray-600 mb-2">See your brand changes in action:</p>
        <iframe src={livePreviewUrl} className="w-full h-64 border" title="Brand Preview"></iframe>
      </div>
    </div>
  );
};

export default BrandingForm;
