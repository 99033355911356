import React, { useState } from 'react';

const Step3Droplet = ({ dropletName, setDropletName, domain, purchased, setDropletCreated, onNext, onPrev }) => {
  const [creating, setCreating] = useState(false);
  const [result, setResult] = useState(null);

  async function createDroplet() {
    setCreating(true);
    const res = await fetch('/api/create-droplet', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ droplet_name: dropletName, domain })
    });
    const data = await res.json();
    setResult(data);
    if (data.status === 'created') {
      setDropletCreated(true);
    }
    setCreating(false);
  }

  return (
    <div>
      <h2 className="font-bold text-xl">Create Your Droplet</h2>
      <p>Your domain: {domain}</p>
      <input
        type="text"
        placeholder="Droplet Name"
        className="border p-2"
        value={dropletName}
        onChange={e => setDropletName(e.target.value)}
      />
      <button
        className="mt-2 p-2 bg-blue-500 text-white"
        onClick={createDroplet}
        disabled={!dropletName || !purchased || creating}
      >
        {creating ? 'Creating...' : 'Create Droplet'}
      </button>

      {result && result.status === 'created' && (
        <div className="mt-4">
          <p className="text-green-700">Droplet created: {result.droplet_name}</p>
          <p>IP: {result.ip_address}</p>
          <button className="mt-2 p-2 bg-blue-500 text-white" onClick={onNext}>Next</button>
        </div>
      )}

      <button className="mt-4 p-2 bg-gray-300" onClick={onPrev}>Back</button>
    </div>
  );
};

export default Step3Droplet;
