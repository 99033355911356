import React, { useState, useEffect, useRef } from 'react';
import PhoneInput from './PhoneInput';
import PaymentForm from './PaymentForm';

const Step2DomainCheck = ({
  domain,
  domainAvailable,
  setDomainAvailable,
  contactInfo,
  setContactInfo,
  purchased,
  setPurchased,
  onNext,
  onPrev
}) => {
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState(null);
  const [useExistingDomain, setUseExistingDomain] = useState(false);
  const fetchedPricing = useRef(false);

  async function checkDomainAvailability() {
    setLoading(true);
    const res = await fetch('/api/check-domain', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ domain })
    });
    const data = await res.json();
    setDomainAvailable(data.available);
    setLoading(false);
  }

  async function fetchPricing() {
    if (!domain || fetchedPricing.current) return;
    fetchedPricing.current = true;
    const res = await fetch('/api/get-pricing', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ domain })
    });
    const data = await res.json();
    if (data.price) {
      setPrice(data.price);
    }
  }

  useEffect(() => {
    if (domainAvailable) {
      fetchPricing();
    }
  }, [domainAvailable]);

  function useMyExistingDomainOption() {
    // User chooses to use their existing domain, skip purchase
    setPurchased(true);
    setUseExistingDomain(true);
  }

  const updateContactInfo = (ci) => {
    setContactInfo(ci);
  };

  return (
    <div>
      <h2 className="font-bold text-xl">Check Domain Availability</h2>
      <p>Domain: {domain}</p>
      <button
        className="mt-2 p-2 bg-yellow-500 text-white"
        onClick={checkDomainAvailability}
        disabled={loading}
      >
        {loading ? 'Checking...' : 'Check Availability'}
      </button>

      {domainAvailable && !purchased && !useExistingDomain && (
        <div className="mt-4">
          <h3 className="font-bold">Domain is available!</h3>
          <p className="mt-2">How would you like to proceed?</p>
          <div className="mt-2">
            <label className="block mb-2">
              <input
                type="radio"
                name="domainOption"
                value="buy"
                defaultChecked
                onChange={() => setUseExistingDomain(false)}
              />
              <span className="ml-2">I want to buy this domain now</span>
            </label>
            <label className="block mb-2">
              <input
                type="radio"
                name="domainOption"
                value="own"
                onChange={() => setUseExistingDomain(true)}
              />
              <span className="ml-2">
                I already own this domain and have it managed elsewhere
              </span>
            </label>
          </div>

          {!useExistingDomain && price && (
            <>
              <p className="mt-4">Domain Price: ${price}</p>
              <p>Please enter your contact details for registration:</p>
              <div className="mt-2">
                <input
                  type="text"
                  placeholder="First Name"
                  className="border p-1 block mt-1"
                  value={contactInfo.firstname}
                  onChange={(e) =>
                    updateContactInfo({ ...contactInfo, firstname: e.target.value })
                  }
                />
                <input
                  type="text"
                  placeholder="Last Name"
                  className="border p-1 block mt-1"
                  value={contactInfo.lastname}
                  onChange={(e) =>
                    updateContactInfo({ ...contactInfo, lastname: e.target.value })
                  }
                />
                <input
                  type="text"
                  placeholder="Address"
                  className="border p-1 block mt-1"
                  value={contactInfo.address1}
                  onChange={(e) =>
                    updateContactInfo({ ...contactInfo, address1: e.target.value })
                  }
                />
                <input
                  type="text"
                  placeholder="City"
                  className="border p-1 block mt-1"
                  value={contactInfo.city}
                  onChange={(e) =>
                    updateContactInfo({ ...contactInfo, city: e.target.value })
                  }
                />
                <input
                  type="text"
                  placeholder="State"
                  className="border p-1 block mt-1"
                  value={contactInfo.stateprovince}
                  onChange={(e) =>
                    updateContactInfo({ ...contactInfo, stateprovince: e.target.value })
                  }
                />
                <input
                  type="text"
                  placeholder="Postal Code"
                  className="border p-1 block mt-1"
                  value={contactInfo.postalcode}
                  onChange={(e) =>
                    updateContactInfo({ ...contactInfo, postalcode: e.target.value })
                  }
                />
                <input
                  type="text"
                  placeholder="Country (e.g. US)"
                  className="border p-1 block mt-1"
                  value={contactInfo.country}
                  onChange={(e) =>
                    updateContactInfo({ ...contactInfo, country: e.target.value })
                  }
                />
                <PhoneInput
                  phone={contactInfo.phone}
                  setPhone={(val) => updateContactInfo({ ...contactInfo, phone: val })}
                />
                <input
                  type="email"
                  placeholder="Email"
                  className="border p-1 block mt-1"
                  value={contactInfo.emailaddress}
                  onChange={(e) =>
                    updateContactInfo({ ...contactInfo, emailaddress: e.target.value })
                  }
                />
              </div>

              {/* After payment success, the user is redirected with payment=success&domain=... 
                  The OnboardingWizard will then handle buying the domain automatically. */}
              <PaymentForm
                domain={domain}
                price={price}
                onPaymentSuccess={() => {
                  // Redirect to onboarding with success params
                  window.location.href = '/onboarding?payment=success&domain=' + domain;
                }}
              />
            </>
          )}

          {useExistingDomain && (
            <div className="mt-4">
              <p>
                You�ve chosen to use a domain you already own. We won�t charge or register this
                domain. Proceed?
              </p>
              <button
                className="mt-2 p-2 bg-green-600 text-white"
                onClick={useMyExistingDomainOption}
              >
                Use My Existing Domain
              </button>
            </div>
          )}
        </div>
      )}

      {useExistingDomain && purchased && (
        <div className="mt-4">
          <h3 className="font-bold text-green-700">Using Your Existing Domain!</h3>
          <p>No purchase needed. We�ll just use {domain} as is.</p>
          <button className="mt-2 p-2 bg-blue-500 text-white" onClick={onNext}>
            Next
          </button>
        </div>
      )}

      {!domainAvailable && (
        <button className="mt-4 p-2 bg-gray-300" onClick={onPrev}>
          Back
        </button>
      )}

      {domainAvailable && !purchased && useExistingDomain && (
        <button className="mt-4 p-2 bg-gray-300" onClick={onPrev}>
          Back
        </button>
      )}

      {purchased && domainAvailable && !useExistingDomain && (
        // If already purchased (rare scenario if state restored?), user can proceed
        <button className="mt-4 p-2 bg-blue-500 text-white" onClick={onNext}>
          Next
        </button>
      )}
    </div>
  );
};

export default Step2DomainCheck;
