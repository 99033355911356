// PageTitle.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PageTitle = () => {
  const location = useLocation();
  
  const getPageTitle = (pathname) => {
    switch (pathname) {
      case '/':
        return "Daniel Rees";
      case '/history':
        return "History - Daniel Rees";
      case '/projects':
        return "Projects - Daniel Rees";
      case '/games':
        return "Distraction Arcade - Daniel Rees";
      case '/games/founders-journey':
        return "Founder's First Journey - Distraction Arcade";
      default:
        return "Daniel Rees";
    }
  };

  useEffect(() => {
    document.title = getPageTitle(location.pathname);
  }, [location]);

  return null;
};

export default PageTitle;

