import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';

const ImageUploader = ({ onImagesUploaded }) => {
  const [files, setFiles] = useState([]);
  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles);
    }
  });

  async function uploadFiles() {
    const formData = new FormData();
    files.forEach(file => formData.append('images', file));
    const res = await fetch('/api/upload-images', {
      method: 'POST',
      body: formData
    });
    const data = await res.json();
    if (data.status === 'images_uploaded') {
      alert(`Uploaded ${data.count} images successfully!`);
      onImagesUploaded();
    } else {
      alert(`Failed to upload images: ${data.error || 'Unknown error'}`);
    }
  }

  return (
    <div>
      <div {...getRootProps()} style={{border:'2px dashed #999', padding:'20px'}}>
        <input {...getInputProps()} />
        <p>Drag & drop some images here, or click to select files</p>
      </div>
      {files.length > 0 && (
        <div className="mt-2">
          <p>{files.length} file(s) selected:</p>
          <ul>
            {files.map((f,i)=><li key={i}>{f.name}</li>)}
          </ul>
          <button
            className="mt-2 p-2 bg-blue-500 text-white rounded"
            onClick={uploadFiles}
          >
            Upload and Analyze
          </button>
        </div>
      )}
    </div>
  );
};

export default ImageUploader;
