import React, { useEffect } from 'react';

const Step5Complete = ({ domain, dropletName }) => {

  useEffect(() => {
    // After load, send domain and droplet to backend to store
    (async () => {
      const res = await fetch('/api/set-deployment', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ domain, dropletName })
      });
      // No need to handle response here, just store for future use.
    })();
  }, [domain, dropletName]);

  return (
    <div>
      <h2 className="font-bold text-xl">Setup Complete!</h2>
      <p>Your SaaS tool is now live on {domain}.</p>
      <p>Droplet: {dropletName}</p>
      <p>You can now access your SaaS frontend and start using it.</p>
      <a
        href="/dashboard"
        className="mt-4 inline-block bg-blue-500 text-white p-2 rounded"
      >
        Go to Dashboard
      </a>
    </div>
  );
};

export default Step5Complete;
