import React from 'react';

const Privacy = () => {
  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold">Privacy Policy</h1>
      <p className="mt-2">
        We value your privacy and are committed to protecting your personal information. This policy
        describes how we collect, use, and safeguard the information you provide to us.
      </p>

      <h2 className="text-xl font-bold mt-4">1. Information We Collect</h2>
      <p className="mt-2">
        We may collect personal information such as your name, email address, and other details
        you choose to provide. We also collect non-personal information about your visit through
        cookies and analytics.
      </p>

      <h2 className="text-xl font-bold mt-4">2. Use of Information</h2>
      <p className="mt-2">
        We use the information collected to improve our website, respond to your inquiries, and
        provide a better user experience. We may also use it to send you updates, marketing
        materials, or other information if you have opted to receive them.
      </p>

      <h2 className="text-xl font-bold mt-4">3. Sharing of Information</h2>
      <p className="mt-2">
        We will not share your personal information with third parties unless required by law or
        when necessary to protect our rights or comply with a legal process.
      </p>

      <h2 className="text-xl font-bold mt-4">4. Cookies</h2>
      <p className="mt-2">
        We use cookies to enhance your browsing experience. You can disable cookies in your browser
        settings, but this may affect the functionality of our website.
      </p>

      <h2 className="text-xl font-bold mt-4">5. Security</h2>
      <p className="mt-2">
        We implement industry-standard security measures to protect your information from
        unauthorized access, alteration, or disclosure. However, no method of transmission over the
        internet is 100% secure.
      </p>

      <h2 className="text-xl font-bold mt-4">6. Changes to This Policy</h2>
      <p className="mt-2">
        We may update this Privacy Policy from time to time. Any changes will be posted on this
        page, and your continued use of the website after changes are posted constitutes acceptance
        of those changes.
      </p>

      <p className="mt-4">
        By using our website, you agree to this Privacy Policy. If you have any questions, please
        contact us.
      </p>
    </div>
  );
};

export default Privacy;
