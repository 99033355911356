import React, { useState, useEffect, useRef } from 'react';

const FoundersFirstJourney = () => {
  const [gameStarted, setGameStarted] = useState(false);
  const [baseHealth, setBaseHealth] = useState(20);
  const [currency, setCurrency] = useState(50);
  const [wave, setWave] = useState(1);
  const [selectedTowerType, setSelectedTowerType] = useState(null);
  const [gameOver, setGameOver] = useState(false);

  const canvasRef = useRef(null);
  const requestRef = useRef();

  // Map Config
  // A simple path from left to right: enemies follow y=canvas.height/2 line for simplicity
  // Towers can be placed above or below the path.
  const pathY = useRef(0);

  // Entities
  const enemiesRef = useRef([]);
  const towersRef = useRef([]);
  const bulletsRef = useRef([]);

  // Wave Config
  // Each wave spawns a sequence of enemies
  // Enemy format: { type: 'B'|'G'|'F', speed, health }
  const waveConfig = {
    1: [{type:'B', count:5, speed:1, health:50}], // Just Bs
    2: [{type:'G', count:8, speed:1.5, health:40}], // Gs
    3: [{type:'F', count:10, speed:2, health:20}], // Fs
    4: [{type:'B',count:3,speed:1,health:60},{type:'G',count:3,speed:1.5,health:40},{type:'F',count:3,speed:2,health:25}],
    5: [{type:'B',count:2,speed:1,health:100},{type:'G',count:2,speed:1.2,health:80},{type:'F',count:2,speed:1.8,health:60}] // harder mixed wave
  };

  const maxWaves = 5;
  let spawnTimer = 0;
  let spawnIndex = 0;
  let currentWaveEnemies = [];

  // Towers
  // Each tower: { x, y, type: 'laser'|'cannon'|'pulse', cooldown, range, damage, reloadTime }
  const towerStats = {
    laser: { cost: 20, damage: 5, range: 100, reloadTime: 20 },
    cannon: { cost: 30, damage: 15, range: 120, reloadTime: 60 },
    pulse: { cost: 40, damage: 8, range: 80, reloadTime: 30, splash:true }
  };

  // Mouse handling for tower placement
  const [mousePos, setMousePos] = useState({ x:0, y:0 });

  const handleCanvasClick = () => {
    if (!selectedTowerType) return;
    const canvas = canvasRef.current;
    if (!canvas) return;

    // Check if can place tower here (e.g., not on the path)
    // Simple rule: can't place on path line +/- some margin
    if (Math.abs(mousePos.y - pathY.current) < 40) return; // too close to path
    // Check currency
    const cost = towerStats[selectedTowerType].cost;
    if (currency < cost) return;

    // Place tower
    towersRef.current.push({
      x: mousePos.x,
      y: mousePos.y,
      type: selectedTowerType,
      cooldown:0,
    });
    setCurrency(c=>c-cost);
  };

  const initGame = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    canvas.width = canvas.parentElement.clientWidth;
    canvas.height = window.innerHeight*0.7;
    pathY.current = canvas.height/2;
    startWave(wave);
  };

  const startWave = (w) => {
    spawnTimer = 0;
    spawnIndex = 0;
    currentWaveEnemies = [];
    let configs = waveConfig[w];
    for (let c of configs) {
      for (let i=0; i<c.count; i++){
        currentWaveEnemies.push({type:c.type, speed:c.speed, health:c.health});
      }
    }
  };

  const spawnEnemy = (enemyData) => {
    enemiesRef.current.push({
      x:0, y:pathY.current,
      type:enemyData.type,
      speed:enemyData.speed,
      health:enemyData.health
    });
  };

  const updateGame = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    // Background
    ctx.fillStyle = '#000030';
    ctx.fillRect(0,0,canvas.width,canvas.height);

    // Draw path
    ctx.fillStyle = '#333';
    ctx.fillRect(0,pathY.current-20,canvas.width,40);
    ctx.strokeStyle='#555';
    ctx.lineWidth=2;
    ctx.strokeRect(0,pathY.current-20,canvas.width,40);

    // Spawn enemies
    if (currentWaveEnemies.length>0) {
      spawnTimer++;
      if (spawnTimer>60) {
        spawnTimer=0;
        let e = currentWaveEnemies.shift();
        spawnEnemy(e);
      }
    } else {
      // If no enemies left and no enemies on board, move to next wave
      if (enemiesRef.current.length===0 && wave<maxWaves) {
        setWave(w=>w+1);
        startWave(wave+1);
      } else if (enemiesRef.current.length===0 && wave===maxWaves) {
        // Victory
        if (!gameOver) setGameOver(true);
      }
    }

    // Update enemies
    enemiesRef.current = enemiesRef.current.filter(e => {
      e.x += e.speed;
      // Draw enemy
      ctx.save();
      let color = '#fff';
      if (e.type==='B') color='#f00';
      if (e.type==='G') color='#0f0';
      if (e.type==='F') color='#00f';
      ctx.fillStyle=color;
      ctx.beginPath();
      ctx.arc(e.x,e.y,10,0,Math.PI*2);
      ctx.fill();
      ctx.fillStyle='#fff';
      ctx.font='10px monospace';
      ctx.fillText(e.type,e.x-3,e.y+3);
      ctx.restore();

      // If reached the end
      if (e.x>canvas.width) {
        setBaseHealth(h=>h-1);
        return false;
      }
      return true;
    });

    // Update towers
    towersRef.current.forEach(t => {
      t.cooldown=Math.max(0,t.cooldown-1);

      // Draw tower
      ctx.save();
      ctx.fillStyle='#ff0';
      if (t.type==='cannon') ctx.fillStyle='#f80';
      if (t.type==='pulse') ctx.fillStyle='#0ff';
      ctx.beginPath();
      ctx.rect(t.x-10,t.y-10,20,20);
      ctx.fill();
      ctx.restore();

      // Find target enemy in range
      let target=null;
      let td = towerStats[t.type];
      let bestDist = td.range;
      enemiesRef.current.forEach(e => {
        const dx=e.x-t.x;
        const dy=e.y-t.y;
        const dist = Math.sqrt(dx*dx+dy*dy);
        if (dist<bestDist) {
          bestDist=dist;
          target=e;
        }
      });

      if (target && t.cooldown===0) {
        // Shoot
        bulletsRef.current.push({
          x:t.x,y:t.y,
          vx:(target.x - t.x)/10,
          vy:(target.y - t.y)/10,
          damage: td.damage,
          splash: td.splash||false
        });
        t.cooldown=td.reloadTime;
      }
    });

    // Update bullets
    bulletsRef.current = bulletsRef.current.filter(b => {
      b.x+=b.vx;
      b.y+=b.vy;

      // Draw bullet
      ctx.save();
      ctx.fillStyle='#fff';
      ctx.beginPath();
      ctx.arc(b.x,b.y,3,0,Math.PI*2);
      ctx.fill();
      ctx.restore();

      // Check collision with enemies
      for (let e of enemiesRef.current) {
        const dx=e.x-b.x;
        const dy=e.y-b.y;
        const dist=Math.sqrt(dx*dx+dy*dy);
        if (dist<10) {
          // Hit
          if (b.splash) {
            // Splash damage
            enemiesRef.current.forEach(e2=>{
              const dx2=e2.x-b.x;
              const dy2=e2.y-b.y;
              const dist2=Math.sqrt(dx2*dx2+dy2*dy2);
              if (dist2<30) e2.health-=b.damage;
            });
          } else {
            e.health-=b.damage;
          }
          return false;
        }
      }
      // Filter out bullet if hit
      return true;
    });

    // Remove dead enemies
    let killed=0;
    enemiesRef.current = enemiesRef.current.filter(e => {
      if (e.health<=0) {
        setCurrency(c=>c+5);
        killed++;
        return false;
      }
      return true;
    });

    // HUD
    ctx.fillStyle='#fff';
    ctx.font='20px monospace';
    ctx.fillText(`Health: ${baseHealth}`,20,30);
    ctx.fillText(`Gold: ${currency}`,20,60);
    ctx.fillText(`Wave: ${wave}/${maxWaves}`,20,90);

    if (baseHealth<=0 && !gameOver) {
      setGameOver(true);
    }

    if (!gameOver) requestRef.current = requestAnimationFrame(updateGame);
  };

  useEffect(()=>{
    if (!gameStarted) return;
    initGame();
    requestRef.current = requestAnimationFrame(updateGame);
    return ()=> cancelAnimationFrame(requestRef.current);
  },[gameStarted]);

  const handleMouseMove=(e)=>{
    const rect=canvasRef.current.getBoundingClientRect();
    setMousePos({x:e.clientX-rect.left,y:e.clientY-rect.top});
  };

  return (
    <div className="min-h-screen bg-black flex flex-col items-center justify-center p-4 relative">
      {!gameStarted && (
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-90 text-center p-8">
          <h1 className="text-4xl font-bold text-pink-500 font-mono mb-4">Founder's Last Defence</h1>
          <p className="text-pink-300 text-xl font-mono mb-8 max-w-xl">
            The BGF Corporation marches on your fortress.  
            B: Heavy brutes  
            G: Balanced grunts  
            F: Fast flankers  
            Build towers, stop them from reaching the end of the path, and save the Founders!
          </p>
          <button
            onClick={()=>setGameStarted(true)}
            className="bg-pink-500 text-black px-8 py-3 font-mono hover:bg-pink-400 transition-colors"
          >
            Start
          </button>
        </div>
      )}

      <div className="flex space-x-4 mb-4">
        <button
          onClick={()=>setSelectedTowerType('laser')}
          className={`px-4 py-2 font-mono ${selectedTowerType==='laser'?'bg-blue-500 text-black':'bg-gray-700 text-white'}`}
        >
          Laser (20g)
        </button>
        <button
          onClick={()=>setSelectedTowerType('cannon')}
          className={`px-4 py-2 font-mono ${selectedTowerType==='cannon'?'bg-orange-500 text-black':'bg-gray-700 text-white'}`}
        >
          Cannon (30g)
        </button>
        <button
          onClick={()=>setSelectedTowerType('pulse')}
          className={`px-4 py-2 font-mono ${selectedTowerType==='pulse'?'bg-cyan-500 text-black':'bg-gray-700 text-white'}`}
        >
          Pulse (40g)
        </button>
      </div>

      <canvas
        ref={canvasRef}
        className="bg-black border border-pink-500 rounded"
        style={{ cursor: selectedTowerType?'crosshair':'default' }}
        onMouseMove={handleMouseMove}
        onClick={handleCanvasClick}
      />

      {gameOver && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 text-center p-4">
          {baseHealth<=0 ? (
            <div className="border-2 border-red-500 bg-black p-8">
              <h2 className="text-3xl font-bold text-red-500 font-mono mb-4">Defeat</h2>
              <p className="text-white font-mono mb-4">The BGF forces overwhelmed your defences...</p>
              <button
                onClick={()=>window.location.reload()}
                className="bg-red-500 text-black px-6 py-3 font-mono hover:bg-red-400"
              >
                Try Again
              </button>
            </div>
          ) : (
            <div className="border-2 border-green-500 bg-black p-8">
              <h2 className="text-3xl font-bold text-green-500 font-mono mb-4">Victory!</h2>
              <p className="text-white font-mono mb-4">You held off the BGF legions and saved the Founders!</p>
              <button
                onClick={()=>window.location.reload()}
                className="bg-green-500 text-black px-6 py-3 font-mono hover:bg-green-400"
              >
                Play Again
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FoundersFirstJourney;
