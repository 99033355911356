import React from 'react';

const Step1DomainInput = ({ domain, setDomain, onNext }) => {
  return (
    <div>
      <h2 className="font-bold text-xl">Welcome to Your SaaS Setup</h2>
      <p>Let's start by choosing a domain name for your SaaS.</p>
      <input
        type="text"
        className="border p-2 mt-2"
        placeholder="e.g. myawesomeservice.com"
        value={domain}
        onChange={e => setDomain(e.target.value)}
      />
      <button
        className="mt-4 bg-blue-500 text-white p-2"
        onClick={onNext}
        disabled={!domain}
      >
        Next
      </button>
    </div>
  );
};

export default Step1DomainInput;
