import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Home,
  Clock,
  Wrench,
  Gamepad2,
  ChevronDown,
  ChevronUp,
  X,
} from 'lucide-react';
import logo from '../DR_icon_logo.png';

const WelcomeMenu = () => {
  const navigate = useNavigate();

  const menuItems = [
    {
      path: '/',
      label: 'Home',
      Icon: Home,
      color: 'text-emerald-500',
      description: 'Main page with general information about Daniel Rees',
    },
    {
      path: '/history',
      label: 'History',
      Icon: Clock,
      color: 'text-amber-500',
      description: "A comprehensive timeline of Daniel's life and achievements",
    },
    {
      path: '/projects',
      label: 'Projects',
      Icon: Wrench,
      color: 'text-indigo-500',
      description: 'Explore what Daniel is currently working on',
    },
    {
      path: '/games',
      label: 'Games',
      Icon: Gamepad2,
      color: 'text-rose-500',
      description: 'Play games developed by Daniel',
    },
  ];

  return (
    <div className="welcome-menu space-y-6 p-2">
      <div className="flex items-center space-x-4 mb-6">
        <div className="w-12 h-12 bg-white rounded-full flex items-center justify-center overflow-hidden border border-gray-100 shadow-sm">
          <img src={logo} alt="DR Logo" className="w-8 h-8 object-contain" />
        </div>
        <h2 className="text-xl font-semibold">Hi! I'm Dan's AI Assistant</h2>
      </div>

      <div className="space-y-4">
        {menuItems.map((item, index) => (
          <button
            key={index}
            onClick={() => navigate(item.path)}
            className="w-full text-left bg-white hover:bg-gray-50 p-4 rounded-xl shadow-sm border border-gray-100 transition-all duration-200 flex items-start space-x-4 group"
          >
            <div className={`${item.color} group-hover:scale-110 transition-transform`}>
              <item.Icon size={24} strokeWidth={2} />
            </div>
            <div>
              <div className="font-medium text-gray-900 mb-1">{item.label}</div>
              <div className="text-sm text-gray-500">{item.description}</div>
            </div>
          </button>
        ))}
      </div>

      <p className="text-sm text-gray-500 text-center mt-4">
        Feel free to ask me anything about these sections!
      </p>
    </div>
  );
};

const MessageContent = ({ content, isFirstMessage }) => {
  const navigate = useNavigate();

  if (isFirstMessage && content === 'WELCOME_MENU') {
    return <WelcomeMenu />;
  }

  const navigationRegex = /\[\[NAVIGATE:([^\]]+)\]\]/g;

  const renderTextWithLinks = (text) => {
    const parts = [];
    let lastIndex = 0;
    let match;

    while ((match = navigationRegex.exec(text)) !== null) {
      if (match.index > lastIndex) {
        parts.push({
          type: 'text',
          content: text.slice(lastIndex, match.index),
        });
      }

      parts.push({
        type: 'link',
        path: match[1],
        content: match[1].replace('/', '') || 'Home',
      });

      lastIndex = match.index + match[0].length;
    }

    if (lastIndex < text.length) {
      parts.push({
        type: 'text',
        content: text.slice(lastIndex),
      });
    }

    return parts.map((part, index) => {
      if (part.type === 'link') {
        return (
          <button
            key={index}
            onClick={() => navigate(part.path)}
            className="inline-flex items-center px-4 py-2 mx-1 bg-blue-50 text-blue-600 rounded-lg hover:bg-blue-100 transition-colors"
          >
            {part.content}
          </button>
        );
      }
      return <span key={index}>{part.content}</span>;
    });
  };

  return <div>{renderTextWithLinks(content)}</div>;
};

const ChatBox = ({ showChat }) => {
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  const [message, setMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isMinimized, setIsMinimized] = useState(true);
  const [isClosed, setIsClosed] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [hasSentWelcomeMessage, setHasSentWelcomeMessage] = useState(false);
  const API_URL = 'https://www.danielrees.com/api/chat';
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (!isMinimized) {
      scrollToBottom();
    }
  }, [chatHistory, isMinimized]);

  if (!showChat || isClosed) {
    return null;
  }

  const handleInputFocus = () => {
    setIsInputFocused(true);
    setIsMinimized(false);
    if (!hasSentWelcomeMessage) {
      setChatHistory([{ type: 'assistant', content: 'WELCOME_MENU' }]);
      setHasSentWelcomeMessage(true);
    }
  };

  const handleMinimize = () => {
    setIsInputFocused(false);
    setIsMinimized(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!message.trim()) return;

    const userMessage = message;
    setChatHistory((prev) => [...prev, { type: 'user', content: userMessage }]);
    setMessage('');
    setIsLoading(true);

    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Origin: 'https://www.danielrees.com',
        },
        body: JSON.stringify({ message: userMessage }),
        mode: 'cors',
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.success) {
        setChatHistory((prev) => [
          ...prev,
          { type: 'assistant', content: data.message },
        ]);
      } else {
        throw new Error(data.message || 'Server error');
      }
    } catch (error) {
      console.error('Chat error:', error);
      setChatHistory((prev) => [
        ...prev,
        {
          type: 'assistant',
          content: `Error: ${error.message}. Please try again in a moment.`,
        },
      ]);
    } finally {
      setIsLoading(false);
      scrollToBottom();
    }
  };

  return (
    <>
      {/* Desktop View */}
      <div 
        className={`hidden md:block fixed bottom-4 ${
          isHomePage 
            ? 'left-1/2 -translate-x-1/2' 
            : 'right-4'
        }`} 
        style={{ 
          zIndex: 50,
          width: isHomePage ? '32rem' : '24rem'
        }}
      >
        <div className={`bg-white rounded-2xl shadow-lg border border-gray-100 overflow-hidden pointer-events-auto ${
          isHomePage ? 'w-[32rem]' : 'w-full'
        }`}>
          {!isMinimized && (
            <>
              {/* Header with controls */}
              <div className="flex justify-between items-center p-4 border-b border-gray-100">
                <div className="text-lg font-medium">Chat</div>
                <div className="flex space-x-2">
                  <button
                    onClick={() => setIsMinimized(true)}
                    className="p-2 hover:bg-gray-100 rounded-full"
                  >
                    <ChevronDown className="w-5 h-5" />
                  </button>
                  <button
                    onClick={() => setIsClosed(true)}
                    className="p-2 hover:bg-gray-100 rounded-full"
                  >
                    <X className="w-5 h-5" />
                  </button>
                </div>
              </div>

              {/* Chat history */}
              <div
                className="p-4 overflow-y-auto"
                style={{
                  maxHeight: 'calc(100vh - 200px)',
                  minHeight: '150px',
                }}
              >
                {chatHistory.map((msg, idx) => (
                  <div
                    key={idx}
                    className={`mb-4 ${
                      msg.type === 'user' ? 'flex justify-end' : 'flex justify-start'
                    }`}
                  >
                    <div
                      className={`max-w-[80%] rounded-2xl p-4 ${
                        msg.type === 'user'
                          ? 'bg-blue-500 text-white'
                          : 'bg-gray-50 text-gray-800'
                      }`}
                    >
                      {msg.type === 'assistant' ? (
                        <MessageContent
                          content={msg.content}
                          isFirstMessage={idx === 0 && msg.content === 'WELCOME_MENU'}
                        />
                      ) : (
                        msg.content
                      )}
                    </div>
                  </div>
                ))}
                {isLoading && (
                  <div className="flex space-x-2 justify-center p-4">
                    <div className="w-3 h-3 bg-blue-500 rounded-full animate-bounce" />
                    <div
                      className="w-3 h-3 bg-blue-500 rounded-full animate-bounce"
                      style={{ animationDelay: '0.2s' }}
                    />
                    <div
                      className="w-3 h-3 bg-blue-500 rounded-full animate-bounce"
                      style={{ animationDelay: '0.4s' }}
                    />
                  </div>
                )}
                <div ref={messagesEndRef} />
              </div>
            </>
          )}
          
          <form
            onSubmit={handleSubmit}
            className="p-4 border-t border-gray-100 bg-gray-50"
          >
            <div className="flex space-x-3">
              <input
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onFocus={handleInputFocus}
                placeholder="Ask me anything..."
                className="flex-1 p-3 rounded-xl border border-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-shadow"
              />
              <button
                type="submit"
                disabled={isLoading}
                className="px-6 py-3 bg-black text-white rounded-xl hover:bg-gray-800 transition-colors disabled:opacity-50 font-medium"
              >
                Send
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* Mobile View */}
      <div className={`md:hidden fixed transition-all duration-300 ${
        isInputFocused ? 'inset-0 bg-white' : 'bottom-0 left-0 right-0'
      }`} style={{ zIndex: 50 }}>
        <div className="bg-white h-full flex flex-col">
          {/* Mobile Header - Only show when fullscreen */}
          {isInputFocused && (
            <div className="flex justify-between items-center p-4 border-b border-gray-100">
              <div className="text-lg font-medium">Chat</div>
              <button
                onClick={handleMinimize}
                className="p-2 hover:bg-gray-100 rounded-full"
              >
                <X className="w-6 h-6" />
              </button>
            </div>
          )}

          {/* Chat Content and Input - Only show when not minimized */}
          {!isMinimized && (
            <>
              <div
                className={`flex-1 overflow-y-auto px-4 ${isInputFocused ? 'py-4' : 'pt-4'}`}
                style={{
                  minHeight: isInputFocused ? '0' : '150px',
                  height: isInputFocused ? 'calc(100vh - 130px)' : undefined,
                }}
              >
                {chatHistory.map((msg, idx) => (
                  <div
                    key={idx}
                    className={`mb-4 ${
                      msg.type === 'user' ? 'flex justify-end' : 'flex justify-start'
                    }`}
                  >
                    <div
                      className={`max-w-[80%] rounded-2xl p-4 ${
                        msg.type === 'user'
                          ? 'bg-blue-500 text-white'
                          : 'bg-gray-50 text-gray-800'
                      }`}
                    >
                      {msg.type === 'assistant' ? (
                        <MessageContent
                          content={msg.content}
                          isFirstMessage={idx === 0 && msg.content === 'WELCOME_MENU'}
                        />
                      ) : (
                        msg.content
                      )}
                    </div>
                  </div>
                ))}
                {isLoading && (
                  <div className="flex space-x-2 justify-center p-4">
                    <div className="w-3 h-3 bg-blue-500 rounded-full animate-bounce" />
                    <div
                      className="w-3 h-3 bg-blue-500 rounded-full animate-bounce"
                      style={{ animationDelay: '0.2s' }}
                    />
                    <div
                      className="w-3 h-3 bg-blue-500 rounded-full animate-bounce"
                      style={{ animationDelay: '0.4s' }}
                    />
                  </div>
                )}
                <div ref={messagesEndRef} />
              </div>
            </>
          )}

          <form
            onSubmit={handleSubmit}
            className={`p-4 bg-gray-50 ${isInputFocused ? 'border-t border-gray-100' : ''}`}
          >
            <div className="flex space-x-3">
              <input
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onFocus={handleInputFocus}
                placeholder="Ask me anything..."
                className="flex-1 p-3 rounded-xl border border-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-shadow"
              />
              <button
                type="submit"
                disabled={isLoading}
                className="px-6 py-3 bg-black text-white rounded-xl hover:bg-gray-800 transition-colors disabled:opacity-50 font-medium"
              >
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ChatBox;