import React, { useState, useEffect, useRef } from 'react';

const IntroAnimation = ({ onComplete }) => {
  const [displayText, setDisplayText] = useState('');
  const hasAnimated = useRef(false);
  const fullText =
    "Hi Guys!\n\nWelcome to my world!\n\nI am a serial entrepreneur.\n\nI built this website because social media isn't personal enough any more.\n\nI wanted to create something that was unique to me.\n\nHave fun exploring!";

  useEffect(() => {
    if (hasAnimated.current) return;
    hasAnimated.current = true;

    let currentChar = 0;
    const typeInterval = setInterval(() => {
      if (currentChar < fullText.length) {
        setDisplayText(fullText.slice(0, currentChar + 1));
        currentChar++;
      } else {
        clearInterval(typeInterval);
        setTimeout(onComplete, 1000);
      }
    }, 50);

    return () => clearInterval(typeInterval);
  }, [onComplete]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-white px-4 py-8 overflow-hidden">
      <div className="max-w-4xl w-full text-center opacity-0 animate-fadeIn">
        <div
          className="whitespace-pre-wrap leading-relaxed font-light text-gray-900 text-2xl sm:text-3xl md:text-4xl lg:text-5xl tracking-wide"
          style={{ fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif' }}
        >
          {displayText}
          {displayText.length < fullText.length && (
            <span className="font-normal text-gray-600 animate-pulse">|</span>
          )}
        </div>
      </div>
      <style jsx>{`
        @keyframes fadeIn {
          0% {
            opacity: 0;
            transform: translateY(10px);
          }
          100% {
            opacity: 1;
            transform: translateY(0);
          }
        }

        .animate-fadeIn {
          animation: fadeIn 1s ease-out forwards;
        }
      `}</style>
    </div>
  );
};

export default IntroAnimation;
