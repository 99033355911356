import React from 'react';

const Login = () => {
  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold">Login Page</h1>
      <p className="mt-2">Sign in to access your dashboard.</p>
      <a href="/api/auth/login" className="mt-4 inline-block bg-blue-500 text-white p-2 rounded">
        Sign in with Google
      </a>
    </div>
  );
};

export default Login;
