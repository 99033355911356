import React, { useState, useEffect, useRef } from 'react';

const OnboardingWizard = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [domain, setDomain] = useState('');
  const [domainAvailable, setDomainAvailable] = useState(false);
  const [contactInfo, setContactInfo] = useState({
    firstname: '',
    lastname: '',
    address1: '',
    city: '',
    stateprovince: '',
    postalcode: '',
    country: 'US',
    phone: '',
    emailaddress: ''
  });
  const [purchased, setPurchased] = useState(false);
  const [dropletName, setDropletName] = useState('');
  const [dropletCreated, setDropletCreated] = useState(false);
  const [generatedCode, setGeneratedCode] = useState('');
  const [testOutput, setTestOutput] = useState('');

  const Step1DomainInput = require('./Step1DomainInput').default;
  const Step2DomainCheck = require('./Step2DomainCheck').default;
  const Step3Droplet = require('./Step3Droplet').default;
  const Step4CodeGeneration = require('./Step4CodeGeneration').default;
  const Step5Complete = require('./Step5Complete').default;

  const [paymentSuccessParams, setPaymentSuccessParams] = useState({ success: false, domainParam: '' });
  const stateRestored = useRef(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paymentSuccess = urlParams.get('payment') === 'success';
    const domainParam = urlParams.get('domain');

    // Restore saved state from localStorage if any
    const saved = localStorage.getItem('onboardingState');
    let restoredStep = 1;
    if (saved) {
      const state = JSON.parse(saved);
      restoredStep = state.step || 1;
      setDomain(state.domain || '');
      setContactInfo(state.contactInfo || contactInfo);
      setPurchased(state.purchased || false);
      setDropletName(state.dropletName || '');
      setGeneratedCode(state.generatedCode || '');
      setTestOutput(state.testOutput || '');
      setDomainAvailable(state.domainAvailable || false);
      setDropletCreated(state.dropletCreated || false);
    }

    setCurrentStep(restoredStep);

    // Store payment success params but don't call attemptAutoBuyDomain yet
    if (paymentSuccess && domainParam) {
      setPaymentSuccessParams({ success: true, domainParam });
    }

    // Mark that initial load is done
    stateRestored.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Only run attemptAutoBuyDomain after state is restored and we have payment success
    if (stateRestored.current && paymentSuccessParams.success && paymentSuccessParams.domainParam) {
      attemptAutoBuyDomain(paymentSuccessParams.domainParam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateRestored.current, paymentSuccessParams]);

  const saveState = () => {
    const state = {
      step: currentStep,
      domain,
      contactInfo,
      purchased,
      dropletName,
      generatedCode,
      testOutput,
      domainAvailable,
      dropletCreated
    };
    localStorage.setItem('onboardingState', JSON.stringify(state));
  };

  async function attemptAutoBuyDomain(domainParam) {
    console.log("Attempting to auto-buy domain:", domainParam);
    // If already purchased, no need to buy again
    if (purchased) {
      console.log("Domain already purchased, skipping.");
      return;
    }

    // Check contact info completeness
    if (!contactInfo.firstname || !contactInfo.lastname || !contactInfo.emailaddress) {
      alert('Missing contact info. Cannot complete domain purchase.');
      return;
    }

    const res = await fetch('/api/buy-domain', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        domain: domainParam,
        registrant: contactInfo,
        admin: contactInfo,
        tech: contactInfo,
        auxbilling: contactInfo
      })
    });

    const data = await res.json();
    console.log("Auto-buy domain response:", data);
    if (data.status === 'purchased') {
      // Domain successfully purchased
      setPurchased(true);
      // Move to droplet creation step (step 3)
      setCurrentStep(3);
      saveState();

      // Clean the URL (remove payment params)
      const cleanUrl = window.location.origin + window.location.pathname;
      window.history.replaceState({}, '', cleanUrl);
    } else {
      // Failed to purchase domain, alert user
      alert(`Failed to purchase domain: ${data.error || 'Unknown error'}`);
    }
  }

  function startOver() {
    localStorage.removeItem('onboardingState');
    window.location.href = '/onboarding';
  }

  return (
    <div className="p-4 relative min-h-screen bg-white">
      <div className="absolute top-4 right-4">
        <button onClick={startOver} className="bg-red-500 text-white px-3 py-1 rounded">
          Start Over
        </button>
      </div>

      {currentStep === 1 && (
        <Step1DomainInput
          domain={domain}
          setDomain={(d) => {
            setDomain(d);
            saveState();
          }}
          onNext={() => {
            setCurrentStep(2);
            saveState();
          }}
        />
      )}
      {currentStep === 2 && (
        <Step2DomainCheck
          domain={domain}
          domainAvailable={domainAvailable}
          setDomainAvailable={(da) => {
            setDomainAvailable(da);
            saveState();
          }}
          contactInfo={contactInfo}
          setContactInfo={(ci) => {
            setContactInfo(ci);
            saveState();
          }}
          purchased={purchased}
          setPurchased={(p) => {
            setPurchased(p);
            saveState();
          }}
          onNext={() => {
            setCurrentStep(3);
            saveState();
          }}
          onPrev={() => {
            setCurrentStep(1);
            saveState();
          }}
        />
      )}
      {currentStep === 3 && (
        <Step3Droplet
          dropletName={dropletName}
          setDropletName={(dn) => {
            setDropletName(dn);
            saveState();
          }}
          domain={domain}
          purchased={purchased}
          setDropletCreated={(dc) => {
            setDropletCreated(dc);
            saveState();
          }}
          onNext={() => {
            setCurrentStep(4);
            saveState();
          }}
          onPrev={() => {
            setCurrentStep(2);
            saveState();
          }}
        />
      )}
      {currentStep === 4 && (
        <Step4CodeGeneration
          generatedCode={generatedCode}
          setGeneratedCode={(gc) => {
            setGeneratedCode(gc);
            saveState();
          }}
          testOutput={testOutput}
          setTestOutput={(to) => {
            setTestOutput(to);
            saveState();
          }}
          onNext={() => {
            setCurrentStep(5);
            saveState();
          }}
          onPrev={() => {
            setCurrentStep(3);
            saveState();
          }}
        />
      )}
      {currentStep === 5 && (
        <Step5Complete
          domain={domain}
          dropletName={dropletName}
        />
      )}
    </div>
  );
};

export default OnboardingWizard;
