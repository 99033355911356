import React from 'react';

const Terms = () => {
  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold">Terms and Conditions</h1>
      <p className="mt-2">
        Welcome to our website. By accessing or using this site, you agree to be bound by these Terms
        and Conditions. If you do not agree, you may not use this site.
      </p>

      <h2 className="text-xl font-bold mt-4">1. Use of the Website</h2>
      <p className="mt-2">
        You agree to use the website in compliance with all applicable laws, rules, and regulations.
        You may not use the website for any unlawful purpose or to solicit others to perform or
        participate in any unlawful acts.
      </p>

      <h2 className="text-xl font-bold mt-4">2. Intellectual Property</h2>
      <p className="mt-2">
        All content provided on this website, including text, graphics, and logos, is owned or
        licensed by us. You may not reproduce, distribute, or exploit any portion of the website
        without our express written permission.
      </p>

      <h2 className="text-xl font-bold mt-4">3. Modifications</h2>
      <p className="mt-2">
        We reserve the right to modify or discontinue the website or these Terms and Conditions at
        any time without prior notice. Your continued use of the website constitutes acceptance of
        any changes.
      </p>

      <h2 className="text-xl font-bold mt-4">4. Limitation of Liability</h2>
      <p className="mt-2">
        We are not liable for any direct, indirect, incidental, or consequential damages arising from
        your use of or inability to use the website.
      </p>

      <h2 className="text-xl font-bold mt-4">5. Governing Law</h2>
      <p className="mt-2">
        These Terms and Conditions are governed by and construed in accordance with the laws of
        [Your Country/State]. Any disputes will be resolved in the courts of that jurisdiction.
      </p>

      <p className="mt-4">
        By continuing to use this website, you acknowledge that you have read, understood, and agree
        to be bound by these Terms and Conditions.
      </p>
    </div>
  );
};

export default Terms;
