import React, { useState, useEffect } from 'react';

const Infrastructure = () => {
  const [domain, setDomain] = useState('');
  const [dropletName, setDropletName] = useState('');
  const [infrastructureStatus, setInfrastructureStatus] = useState(null);
  const [contactInfo, setContactInfo] = useState({
    firstname: '',
    lastname: '',
    address1: '',
    city: '',
    stateprovince: '',
    postalcode: '',
    country: 'US',
    phone: '',
    emailaddress: ''
  });
  const [loading, setLoading] = useState(false);
  const [domainPrice, setDomainPrice] = useState(null);

  useEffect(() => {
    fetchCurrentDeployment();
  }, []);

  async function fetchCurrentDeployment() {
    const res = await fetch('/api/get-deployment');
    if (res.ok) {
      const data = await res.json();
      if (data.domain && data.dropletName) {
        setDomain(data.domain);
        setDropletName(data.dropletName);
        setInfrastructureStatus('done');
      } else {
        setInfrastructureStatus('pending');
      }
    } else {
      setInfrastructureStatus('pending');
    }
  }

  async function checkDomainAvailability() {
    if (!domain) return;
    setLoading(true);
    const res = await fetch('/api/check-domain', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ domain })
    });
    const data = await res.json();
    setLoading(false);
    if (data.available) {
      await fetchPricing();
      alert('Domain is available!');
    } else {
      alert('Domain is not available. Try another.');
    }
  }

  async function fetchPricing() {
    if (!domain) return;
    const res = await fetch('/api/get-pricing', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ domain })
    });
    const data = await res.json();
    if (data.price) {
      setDomainPrice(data.price);
    }
  }

  async function buyDomain() {
    if (!domain || !contactInfo.firstname || !contactInfo.lastname || !contactInfo.emailaddress) {
      alert('Missing contact info or domain.');
      return;
    }
    setLoading(true);
    const res = await fetch('/api/buy-domain', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        domain,
        registrant: contactInfo,
        admin: contactInfo,
        tech: contactInfo,
        auxbilling: contactInfo
      })
    });
    const data = await res.json();
    setLoading(false);
    if (data.status === 'purchased') {
      alert('Domain purchased successfully!');
    } else {
      alert(`Failed to purchase domain: ${data.error || 'Unknown error'}`);
    }
  }

  async function createDroplet() {
    if (!dropletName || !domain) {
      alert('Need a domain and droplet name');
      return;
    }
    setLoading(true);
    const res = await fetch('/api/create-droplet', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ droplet_name: dropletName, domain })
    });
    const data = await res.json();
    setLoading(false);
    if (data.status === 'created') {
      alert('Droplet created successfully!');
      // After droplet created, domain + dropletName recorded in deployment.
      setInfrastructureStatus('done');
    } else {
      alert(`Failed to create droplet: ${data.error || 'Unknown error'}`);
    }
  }

  if (infrastructureStatus === 'done') {
    return (
      <div className="space-y-4">
        <h2 className="text-2xl font-bold">Infrastructure</h2>
        <p className="text-gray-600">Your infrastructure is set up!</p>
        <div className="bg-white border rounded p-4 space-y-2">
          <h3 className="font-bold">Current Setup:</h3>
          <p>Domain: {domain}</p>
          <p>Droplet: {dropletName}</p>
          <p className="text-sm text-gray-600">You can now manage code, pages, branding and more using the other tabs.</p>
        </div>
      </div>
    );
  }

  // If pending or first time
  return (
    <div className="space-y-4">
      <h2 className="text-2xl font-bold">Infrastructure Setup</h2>
      <p className="text-gray-600">
        Set up your domain and droplet. Start by entering a domain and checking availability.
      </p>

      <div className="bg-white border rounded p-4 space-y-4">
        <div>
          <label className="block mb-1 font-bold">Domain Name:</label>
          <input
            type="text"
            className="border p-2 w-full"
            placeholder="e.g. myawesomeservice.com"
            value={domain}
            onChange={(e) => setDomain(e.target.value)}
          />
          <button
            className="mt-2 p-2 bg-yellow-500 text-white"
            onClick={checkDomainAvailability}
            disabled={loading || !domain}
          >
            {loading ? 'Checking...' : 'Check Availability'}
          </button>
          {domainPrice && <p className="mt-2">Price: ${domainPrice}</p>}
        </div>

        {domainPrice && (
          <div className="bg-gray-50 p-4 rounded">
            <h3 className="font-bold mb-2">Contact Info for Domain Purchase</h3>
            <div className="space-y-1">
              <input
                type="text"
                className="border p-1 w-full"
                placeholder="First Name"
                value={contactInfo.firstname}
                onChange={(e) => setContactInfo({ ...contactInfo, firstname: e.target.value })}
              />
              <input
                type="text"
                className="border p-1 w-full"
                placeholder="Last Name"
                value={contactInfo.lastname}
                onChange={(e) => setContactInfo({ ...contactInfo, lastname: e.target.value })}
              />
              <input
                type="text"
                className="border p-1 w-full"
                placeholder="Address"
                value={contactInfo.address1}
                onChange={(e) => setContactInfo({ ...contactInfo, address1: e.target.value })}
              />
              <input
                type="text"
                className="border p-1 w-full"
                placeholder="City"
                value={contactInfo.city}
                onChange={(e) => setContactInfo({ ...contactInfo, city: e.target.value })}
              />
              <input
                type="text"
                className="border p-1 w-full"
                placeholder="State/Province"
                value={contactInfo.stateprovince}
                onChange={(e) => setContactInfo({ ...contactInfo, stateprovince: e.target.value })}
              />
              <input
                type="text"
                className="border p-1 w-full"
                placeholder="Postal Code"
                value={contactInfo.postalcode}
                onChange={(e) => setContactInfo({ ...contactInfo, postalcode: e.target.value })}
              />
              <input
                type="text"
                className="border p-1 w-full"
                placeholder="Country"
                value={contactInfo.country}
                onChange={(e) => setContactInfo({ ...contactInfo, country: e.target.value })}
              />
              <input
                type="text"
                className="border p-1 w-full"
                placeholder="Phone"
                value={contactInfo.phone}
                onChange={(e) => setContactInfo({ ...contactInfo, phone: e.target.value })}
              />
              <input
                type="email"
                className="border p-1 w-full"
                placeholder="Email Address"
                value={contactInfo.emailaddress}
                onChange={(e) => setContactInfo({ ...contactInfo, emailaddress: e.target.value })}
              />

              <button
                className="mt-2 p-2 bg-blue-500 text-white"
                onClick={buyDomain}
                disabled={loading}
              >
                {loading ? 'Purchasing...' : 'Buy Domain'}
              </button>
            </div>
          </div>
        )}

        {domainPrice && (
          <div className="bg-gray-50 p-4 rounded">
            <h3 className="font-bold mb-2">Create Your Droplet</h3>
            <input
              type="text"
              className="border p-2 w-full"
              placeholder="Droplet Name"
              value={dropletName}
              onChange={(e) => setDropletName(e.target.value)}
            />
            <button
              className="mt-2 p-2 bg-green-500 text-white"
              onClick={createDroplet}
              disabled={loading || !dropletName}
            >
              {loading ? 'Creating...' : 'Create Droplet'}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Infrastructure;
