import React, { useState, useEffect } from 'react';

const PageManager = () => {
  const [pages, setPages] = useState([]);
  const [newPagePrompt, setNewPagePrompt] = useState('');
  const [loading, setLoading] = useState(false);

  async function fetchPages() {
    const res = await fetch('/api/list-pages');
    const data = await res.json();
    if (data.pages) {
      setPages(data.pages);
    }
  }

  useEffect(() => {
    fetchPages();
  }, []);

  async function addNewPage() {
    if (!newPagePrompt) return;
    setLoading(true);
    const res = await fetch('/api/generate-page', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ prompt: newPagePrompt })
    });
    const data = await res.json();
    setLoading(false);
    if (data.status === 'page_created') {
      alert('New page created!');
      setNewPagePrompt('');
      fetchPages();
    } else {
      alert(`Failed to create page: ${data.error || 'Unknown error'}`);
    }
  }

  return (
    <div className="space-y-4">
      <h2 className="text-2xl font-bold">Page Manager</h2>
      <p className="text-gray-600">Create and manage the pages of your SaaS. Add new pages by describing what you need.</p>

      <h3 className="font-bold mt-4">Existing Pages:</h3>
      {pages.length === 0 ? (
        <p className="text-gray-500">No pages yet. Add one below.</p>
      ) : (
        <ul className="list-disc list-inside bg-white border p-2 rounded space-y-1">
          {pages.map((page, idx) => (
            <li key={idx} className="text-gray-700">{page}</li>
          ))}
        </ul>
      )}

      <h3 className="font-bold mt-4">Add a New Page</h3>
      <textarea
        className="border p-2 w-full h-24"
        placeholder="Describe the page you want..."
        value={newPagePrompt}
        onChange={e => setNewPagePrompt(e.target.value)}
      />
      <button
        className="mt-2 p-2 bg-blue-500 text-white"
        onClick={addNewPage}
        disabled={loading}
      >
        {loading ? 'Creating...' : 'Generate New Page'}
      </button>
    </div>
  );
};

export default PageManager;
