import React from 'react';

const NavBar = () => {
  return (
    <div className="h-16 bg-white border-b flex items-center justify-between px-4">
      <div className="text-xl font-bold">Your SaaS Dashboard</div>
      <div className="flex items-center space-x-4">
        <a href="/auth/logout" className="text-red-500 hover:underline">Logout</a>
      </div>
    </div>
  );
};

export default NavBar;
