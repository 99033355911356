import React from 'react';

const Timeline = () => {
  return (
    <div className="min-h-screen bg-white p-8">
      <h1 className="text-3xl font-bold mb-4">History of Dan</h1>
      <p>Timeline coming soon...</p>
    </div>
  );
};

export default Timeline;
