import React, { useMemo, useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { 
  Activity, 
  CreditCard as CreditCardIcon, 
  DollarSign, 
  ArrowUpRight, 
  ArrowDownRight, 
  Wallet, 
  BarChart3,
  Home,
  FileText,
  Settings
} from 'lucide-react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';

// Custom Tooltip for Charts
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white border border-[#1B4D3E] rounded p-2 shadow-sm text-sm">
        <p className="font-semibold text-[#1B4D3E] mb-1">{label}</p>
        {payload.map((entry, index) => (
          <div key={index} className="flex items-center justify-between">
            <span className="text-gray-700">{entry.dataKey}:</span>
            <span className={`font-semibold ${entry.dataKey === 'grossMargin' ? 'text-green-600' : 'text-[#1B4D3E]'}`}>
              {entry.dataKey === 'grossMargin' ? `${entry.value}%` : `£${entry.value.toLocaleString()}`}
            </span>
          </div>
        ))}
      </div>
    );
  }
  return null;
};

// Utility function to generate random data
const randomInRange = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

// Generate monthly data
const generateMonthlyData = () => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  
  const thisYear = months.map(month => ({
    month,
    revenue: randomInRange(80_000, 120_000),
    grossMargin: randomInRange(50, 60),
    marketing: randomInRange(80_000, 120_000),
    fixedCosts: randomInRange(2_000, 3_000),
    ebitda: randomInRange(90_000, 110_000),
  }));

  const lastYear = months.map((month, idx) => ({
    month,
    revenue: Math.round(thisYear[idx].revenue * (0.5 + Math.random())),
    grossMargin: thisYear[idx].grossMargin + randomInRange(-5, 5),
    marketing: thisYear[idx].marketing * (0.5 + Math.random()),
    fixedCosts: thisYear[idx].fixedCosts,
    ebitda: thisYear[idx].ebitda * (0.5 + Math.random()),
  }));

  return { thisYear, lastYear, months };
};

// Calculate VAR (difference %) between this year and last year totals
const calculateVar = (thisYear, lastYear, key) => {
  const totalThis = thisYear.reduce((sum, m) => sum + m[key], 0);
  const totalLast = lastYear.reduce((sum, m) => sum + m[key], 0);
  const diff = ((totalThis - totalLast) / (totalLast || 1)) * 100;
  return diff.toFixed(2);
};

// Calculate monthly VAR
const calculateMonthlyVar = (thisValue, lastValue) => {
  if (lastValue === 0) return '0.00'; // Avoid divide by zero
  return (((thisValue - lastValue) / lastValue) * 100).toFixed(2);
};

// Simple toggle switch (no label text, just the switch)
function ToggleSwitch({ checked, onChange }) {
  return (
    <div className="relative inline-block w-10 align-middle select-none transition duration-200 ease-in">
      <input
        type="checkbox"
        className="absolute opacity-0 w-0 h-0"
        checked={checked}
        onChange={onChange}
      />
      <div className={`block w-10 h-5 rounded-full ${checked ? 'bg-[#1B4D3E]' : 'bg-gray-300'}`}></div>
      <div
        className={`dot absolute left-0.5 top-0.5 w-4 h-4 bg-white rounded-full transition transform ${checked ? 'translate-x-5' : ''}`}
      ></div>
    </div>
  );
}

export default function Dashboard() {
  const [thisYearData, setThisYearData] = useState([]);
  const [lastYearData, setLastYearData] = useState([]);
  const [months, setMonths] = useState([]);

  // States for toggles
  const [payablesOverdue, setPayablesOverdue] = useState(false);
  const [receivablesOverdue, setReceivablesOverdue] = useState(false);

  // States for filters
  const [dateRange, setDateRange] = useState('YTD');
  const [country, setCountry] = useState('All');

  useEffect(() => {
    const { thisYear, lastYear, months } = generateMonthlyData();
    setThisYearData(thisYear);
    setLastYearData(lastYear);
    setMonths(months);
  }, []);

  // Summaries
  const totalRevenueThisYear = thisYearData.reduce((sum, m) => sum + m.revenue, 0);
  const avgMarginThisYear = thisYearData.reduce((sum, m) => sum + m.grossMargin, 0) / (thisYearData.length || 1);
  const totalEbitdaThisYear = thisYearData.reduce((sum, m) => sum + m.ebitda, 0);

  // Compute arrays for payables/receivables dynamically based on toggle
  const payables = useMemo(() => ([
    { name: 'Supplier 1', amount: payablesOverdue ? randomInRange(60_000, 130_000) : randomInRange(50_000, 120_000) },
    { name: 'Supplier 2', amount: payablesOverdue ? randomInRange(25_000, 65_000) : randomInRange(20_000, 60_000) },
    { name: 'Supplier 3', amount: payablesOverdue ? randomInRange(25_000, 65_000) : randomInRange(20_000, 60_000) },
    { name: 'Supplier 4', amount: payablesOverdue ? randomInRange(10_000, 25_000) : randomInRange(5_000, 20_000) },
    { name: 'Supplier 5', amount: payablesOverdue ? randomInRange(5_000, 15_000) : randomInRange(1_000, 10_000) },
  ]), [payablesOverdue]);

  const receivables = useMemo(() => ([
    { name: 'Customer 1', amount: receivablesOverdue ? randomInRange(60_000, 130_000) : randomInRange(50_000, 120_000) },
    { name: 'Customer 2', amount: receivablesOverdue ? randomInRange(25_000, 65_000) : randomInRange(20_000, 60_000) },
    { name: 'Customer 3', amount: receivablesOverdue ? randomInRange(25_000, 65_000) : randomInRange(20_000, 60_000) },
    { name: 'Customer 4', amount: receivablesOverdue ? randomInRange(10_000, 25_000) : randomInRange(5_000, 20_000) },
    { name: 'Customer 5', amount: receivablesOverdue ? randomInRange(5_000, 15_000) : randomInRange(1_000, 10_000) },
  ]), [receivablesOverdue]);

  const totalPayables = payables.reduce((sum, p) => sum + p.amount, 0);
  const totalReceivables = receivables.reduce((sum, r) => sum + r.amount, 0);

  const taxAccounts = [
    { name: 'VAT', amount: randomInRange(80_000, 120_000), nextPayment: '09/12/2024' },
    { name: 'PAYE', amount: randomInRange(30_000, 70_000), nextPayment: '22/12/2024' },
    { name: 'CORP TAX', amount: randomInRange(20_000, 40_000), nextPayment: '31/01/2025' },
  ];
  const totalTax = taxAccounts.reduce((sum, t) => sum + t.amount, 0);

  const cashOnHandAccounts = [
    { logo: 'hsbc-logo.png', amount: randomInRange(500_000, 1_200_000) },
    { logo: 'paypal-logo.png', amount: randomInRange(100_000, 300_000) },
    { logo: 'monzo-logo.png', amount: randomInRange(0, 50_000) },
  ];
  const totalCashOnHand = cashOnHandAccounts.reduce((sum, acc) => sum + acc.amount, 0);

  const creditCards = [
    { logo: 'amex-logo.png', amount: randomInRange(50_000, 120_000) },
    { logo: 'hsbc-logo.png', amount: randomInRange(20_000, 30_000) },
  ];
  const totalCreditCards = creditCards.reduce((sum, cc) => sum + cc.amount, 0);

  // VAR calculations for totals
  const varRevenue = calculateVar(thisYearData, lastYearData, 'revenue');
  const varGrossMargin = calculateVar(thisYearData, lastYearData, 'grossMargin');
  const varMarketing = calculateVar(thisYearData, lastYearData, 'marketing');
  const varFixedCosts = calculateVar(thisYearData, lastYearData, 'fixedCosts');
  const varEbitda = calculateVar(thisYearData, lastYearData, 'ebitda');

  const varData = [
    { label: 'Revenue', value: varRevenue },
    { label: 'Gross Margin', value: varGrossMargin },
    { label: 'Marketing Spend', value: varMarketing },
    { label: 'Fixed Costs', value: varFixedCosts },
    { label: 'EBITDA', value: varEbitda },
  ];

  // Metrics for the big table
  const metrics = [
    { label: 'Revenue', key: 'revenue', format: v => `�${v.toLocaleString()}` },
    { label: 'Gross Margin', key: 'grossMargin', format: v => `${v.toFixed(2)}%` },
    { label: 'Marketing Spend', key: 'marketing', format: v => `�${v.toLocaleString()}` },
    { label: 'Fixed Costs', key: 'fixedCosts', format: v => `�${v.toLocaleString()}` },
    { label: 'EBITDA', key: 'ebitda', format: v => `�${v.toLocaleString()}` },
  ];

  return (
    <div className="min-h-screen flex flex-col bg-[#F8FAFB] font-sans text-gray-900">
      {/* Top Bar */}
      <header className="h-16 bg-white border-b border-gray-200 flex items-center justify-between px-6">
        <div className="flex items-center h-full">
          <img src="/e-clarity-logo.png" alt="eClarity Logo" className="h-10 w-auto mr-4" />
        </div>
        <div className="flex items-center space-x-4">
          <div className="text-sm text-gray-700">Hello, Seamus</div>
          <img
            src="/seamus.png"
            alt="Seamus Avatar"
            className="h-10 w-10 rounded-full object-cover"
          />
        </div>
      </header>

      <div className="flex-1 flex">
        {/* Sidebar */}
        <aside className="w-64 bg-[#1B4D3E] text-white flex flex-col">
          <nav className="flex-1 p-4 space-y-4 border-t border-white/10">
            <a href="#" className="flex items-center p-2 rounded hover:bg-white/10 transition">
              <Home className="w-5 h-5 mr-3" />
              <span>Dashboard</span>
            </a>
            <a href="#" className="flex items-center p-2 rounded hover:bg-white/10 transition">
              <BarChart3 className="w-5 h-5 mr-3" />
              <span>Analytics</span>
            </a>
            <a href="#" className="flex items-center p-2 rounded hover:bg-white/10 transition">
              <FileText className="w-5 h-5 mr-3" />
              <span>Reports</span>
            </a>
            <a href="#" className="flex items-center p-2 rounded hover:bg-white/10 transition">
              <Settings className="w-5 h-5 mr-3" />
              <span>Settings</span>
            </a>
          </nav>
        </aside>

        {/* Main Content */}
        <div className="flex-1 p-6 space-y-8 overflow-auto">
          {/* Heading & Filters */}
          <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between">
            <div className="mb-4 lg:mb-0">
              <h1 className="text-3xl font-semibold text-[#1B4D3E]">eClarity Financial Dashboard</h1>
              <p className="text-sm text-gray-500 mt-1">Comprehensive overview of your financials</p>
            </div>
            <div className="flex flex-col sm:flex-row items-start sm:items-center space-y-4 sm:space-y-0 sm:space-x-4">
              {/* Date Range Filter */}
              <div className="flex items-center space-x-2">
                <label className="text-sm text-gray-600">Date Range:</label>
                <select
                  value={dateRange}
                  onChange={e => setDateRange(e.target.value)}
                  className="border border-gray-300 rounded px-2 py-1 text-sm focus:outline-none focus:ring-1 focus:ring-[#1B4D3E]"
                >
                  <option value="YTD">Year to Date</option>
                  <option value="MTD">Month to Date</option>
                  <option value="QTD">Quarter to Date</option>
                  <option value="1Y">1 Year</option>
                </select>
              </div>
              {/* Country Filter */}
              <div className="flex items-center space-x-2">
                <label className="text-sm text-gray-600">Country:</label>
                <select
                  value={country}
                  onChange={e => setCountry(e.target.value)}
                  className="border border-gray-300 rounded px-2 py-1 text-sm focus:outline-none focus:ring-1 focus:ring-[#1B4D3E]"
                >
                  <option value="All">All</option>
                  <option value="UK">UK</option>
                  <option value="US">US</option>
                  <option value="EU">EU</option>
                </select>
              </div>
            </div>
          </div>

          {/* Top Financial Snapshot */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-6">
            {/* Cash on Hand */}
            <Card className="shadow-sm border border-gray-100 rounded-lg">
              <CardHeader>
                <CardTitle className="text-lg font-semibold text-gray-800">Cash On Hand</CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-2xl font-bold text-gray-900">�{totalCashOnHand.toLocaleString()}</p>
                <div className="mt-4 grid grid-cols-1 gap-2">
                  {cashOnHandAccounts.map((acc, idx) => (
                    <div key={idx} className="flex items-center space-x-3 border border-gray-200 rounded p-2">
                      <img src={`/${acc.logo}`} alt="" className="h-8 w-auto" />
                      <span className="text-gray-800 font-medium">�{acc.amount.toLocaleString()}</span>
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>

            {/* Tax Accounts */}
            <Card className="shadow-sm border border-gray-100 rounded-lg">
              <CardHeader>
                <CardTitle className="text-lg font-semibold text-gray-800">Tax Accounts</CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-2xl font-bold text-gray-900">�{totalTax.toLocaleString()}</p>
                <div className="mt-4 space-y-1 text-sm text-gray-700">
                  {taxAccounts.map((t, idx) => (
                    <div key={idx} className="flex justify-between">
                      <span>{t.name}</span>
                      <span>�{t.amount.toLocaleString()}</span>
                    </div>
                  ))}
                </div>
                <div className="mt-4 space-y-1 text-xs text-gray-500">
                  {taxAccounts.map((t, idx) => (
                    <div key={idx} className="flex justify-between">
                      <span>{t.name} next payment</span>
                      <span>{t.nextPayment}</span>
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>

            {/* Credit Cards */}
            <Card className="shadow-sm border border-gray-100 rounded-lg">
              <CardHeader>
                <CardTitle className="text-lg font-semibold text-gray-800">Credit Cards</CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-2xl font-bold text-gray-900">�{totalCreditCards.toLocaleString()}</p>
                <div className="mt-4 grid grid-cols-1 gap-2">
                  {creditCards.map((cc, idx) => (
                    <div key={idx} className="flex items-center space-x-3 border border-gray-200 rounded p-2">
                      <img src={`/${cc.logo}`} alt="" className="h-8 w-auto" />
                      <span className="text-gray-800 font-medium">�{cc.amount.toLocaleString()}</span>
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>

            {/* Payables */}
            <Card className="shadow-sm border border-gray-100 rounded-lg">
              <CardHeader>
                <div className="flex items-center justify-between">
                  <CardTitle className="text-lg font-semibold text-gray-800">
                    Payables ({payablesOverdue ? 'Overdue' : 'Due'})
                  </CardTitle>
                  <ToggleSwitch
                    checked={payablesOverdue}
                    onChange={() => setPayablesOverdue(!payablesOverdue)}
                  />
                </div>
              </CardHeader>
              <CardContent>
                <p className="text-2xl font-bold text-gray-900">�{totalPayables.toLocaleString()}</p>
                <div className="mt-4 space-y-1 text-sm text-gray-700">
                  {payables.map((p, idx) => (
                    <div key={idx} className="flex justify-between">
                      <span>{p.name}</span>
                      <span>�{p.amount.toLocaleString()}</span>
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>

            {/* Receivables */}
            <Card className="shadow-sm border border-gray-100 rounded-lg">
              <CardHeader>
                <div className="flex items-center justify-between">
                  <CardTitle className="text-lg font-semibold text-gray-800">
                    Receivables ({receivablesOverdue ? 'Overdue' : 'Due'})
                  </CardTitle>
                  <ToggleSwitch
                    checked={receivablesOverdue}
                    onChange={() => setReceivablesOverdue(!receivablesOverdue)}
                  />
                </div>
              </CardHeader>
              <CardContent>
                <p className="text-2xl font-bold text-gray-900">�{totalReceivables.toLocaleString()}</p>
                <div className="mt-4 space-y-1 text-sm text-gray-700">
                  {receivables.map((r, idx) => (
                    <div key={idx} className="flex justify-between">
                      <span>{r.name}</span>
                      <span>�{r.amount.toLocaleString()}</span>
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>
          </div>

          {/* KPI Summary Cards */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            <Card className="shadow-sm border border-gray-100 rounded-lg hover:shadow-md transition">
              <CardContent className="pt-6">
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-sm text-gray-500">Total Revenue (This Year)</p>
                    <p className="text-2xl font-semibold text-gray-900">�{(totalRevenueThisYear / 1_000_000).toFixed(2)}M</p>
                  </div>
                  <div className="p-3 bg-[#1B4D3E]/10 rounded-full">
                    <DollarSign className="w-6 h-6 text-[#1B4D3E]" />
                  </div>
                </div>
                <div className="mt-4 flex items-center text-sm">
                  <ArrowUpRight className="w-4 h-4 text-green-500 mr-1" />
                  <span className="text-green-500">12.5%</span>
                  <span className="text-gray-500 ml-2">vs last year</span>
                </div>
              </CardContent>
            </Card>

            <Card className="shadow-sm border border-gray-100 rounded-lg hover:shadow-md transition">
              <CardContent className="pt-6">
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-sm text-gray-500">Gross Margin (Avg)</p>
                    <p className="text-2xl font-semibold text-gray-900">{avgMarginThisYear.toFixed(1)}%</p>
                  </div>
                  <div className="p-3 bg-[#1B4D3E]/10 rounded-full">
                    <Activity className="w-6 h-6 text-[#1B4D3E]" />
                  </div>
                </div>
                <div className="mt-4 flex items-center text-sm">
                  <ArrowUpRight className="w-4 h-4 text-green-500 mr-1" />
                  <span className="text-green-500">2.4%</span>
                  <span className="text-gray-500 ml-2">vs last year</span>
                </div>
              </CardContent>
            </Card>

            <Card className="shadow-sm border border-gray-100 rounded-lg hover:shadow-md transition">
              <CardContent className="pt-6">
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-sm text-gray-500">EBITDA (This Year)</p>
                    <p className="text-2xl font-semibold text-gray-900">�{(totalEbitdaThisYear / 1_000_000).toFixed(2)}M</p>
                  </div>
                  <div className="p-3 bg-[#1B4D3E]/10 rounded-full">
                    <Wallet className="w-6 h-6 text-[#1B4D3E]" />
                  </div>
                </div>
                <div className="mt-4 flex items-center text-sm">
                  <ArrowDownRight className="w-4 h-4 text-red-500 mr-1" />
                  <span className="text-red-500">4.2%</span>
                  <span className="text-gray-500 ml-2">vs last year</span>
                </div>
              </CardContent>
            </Card>

            <Card className="shadow-sm border border-gray-100 rounded-lg hover:shadow-md transition">
              <CardContent className="pt-6">
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-sm text-gray-500">Credit Card Balance</p>
                    <p className="text-2xl font-semibold text-gray-900">�{totalCreditCards.toLocaleString()}</p>
                  </div>
                  <div className="p-3 bg-[#1B4D3E]/10 rounded-full">
                    <CreditCardIcon className="w-6 h-6 text-[#1B4D3E]" />
                  </div>
                </div>
                <div className="mt-4 flex items-center text-sm">
                  <ArrowDownRight className="w-4 h-4 text-red-500 mr-1" />
                  <span className="text-red-500">1.5%</span>
                  <span className="text-gray-500 ml-2">vs last year</span>
                </div>
              </CardContent>
            </Card>
          </div>

          {/* Charts */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <Card className="shadow-sm border border-gray-100 rounded-lg">
              <CardHeader>
                <CardTitle className="text-gray-800 font-semibold">Revenue Trend (This Year)</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="h-80">
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={thisYearData}>
                      <CartesianGrid strokeDasharray="3 3" stroke="#ebebeb" />
                      <XAxis dataKey="month" stroke="#666" />
                      <YAxis stroke="#666" />
                      <CustomTooltip />
                      <Line 
                        type="monotone" 
                        dataKey="revenue" 
                        stroke="#1B4D3E" 
                        strokeWidth={2}
                        dot={false}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </CardContent>
            </Card>

            <Card className="shadow-sm border border-gray-100 rounded-lg">
              <CardHeader>
                <CardTitle className="text-gray-800 font-semibold">EBITDA &amp; Margin Trend (This Year)</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="h-80">
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={thisYearData}>
                      <CartesianGrid strokeDasharray="3 3" stroke="#ebebeb" />
                      <XAxis dataKey="month" stroke="#666" />
                      <YAxis yAxisId="left" stroke="#666" />
                      <YAxis yAxisId="right" orientation="right" stroke="#666" />
                      <CustomTooltip />
                      <Line 
                        yAxisId="left"
                        type="monotone" 
                        dataKey="ebitda" 
                        stroke="#1B4D3E" 
                        strokeWidth={2}
                        dot={false}
                      />
                      <Line 
                        yAxisId="right"
                        type="monotone" 
                        dataKey="grossMargin" 
                        stroke="#2ecc71" 
                        strokeWidth={2}
                        dot={false}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </CardContent>
            </Card>
          </div>

          {/* Yearly Comparison & VAR */}
          <div className="bg-white shadow-sm border border-gray-100 rounded-lg p-6 space-y-6">
            <h2 className="text-xl font-semibold text-[#1B4D3E]">This Year vs Last Year</h2>
            <div className="overflow-auto">
              <table className="text-sm border-collapse">
                <thead className="bg-gray-100 text-gray-600">
                  <tr>
                    <th className="p-3 text-left font-semibold">Metric</th>
                    {months.map((m) => (
                      <th key={m} colSpan={3} className="p-3 font-semibold text-center border-l border-gray-200">{m}</th>
                    ))}
                    <th colSpan={3} className="p-3 font-semibold text-center border-l border-gray-200">Total</th>
                  </tr>
                  <tr className="bg-gray-100 text-xs text-gray-500">
                    <th className="p-2 text-left"></th>
                    {months.map((m,i) => (
                      <React.Fragment key={i}>
                        <th className="p-2 text-center border-l border-gray-200">This Year</th>
                        <th className="p-2 text-center">Last Year</th>
                        <th className="p-2 text-center">VAR%</th>
                      </React.Fragment>
                    ))}
                    <th className="p-2 text-center border-l border-gray-200">This Year</th>
                    <th className="p-2 text-center font-semibold">Last Year</th>
                    <th className="p-2 text-center font-semibold">VAR%</th>
                  </tr>
                </thead>
                <tbody className="text-gray-800 divide-y divide-gray-200">
                  {metrics.map((metric, idx) => {
                    const totalThis = thisYearData.reduce((sum, m) => sum + m[metric.key], 0);
                    const totalLast = lastYearData.reduce((sum, m) => sum + m[metric.key], 0);
                    const totalVar = calculateMonthlyVar(totalThis, totalLast);
                    const totalVarPositive = parseFloat(totalVar) >= 0;

                    return (
                      <tr key={idx} className="hover:bg-gray-50 transition">
                        <td className="p-3 font-semibold">{metric.label}</td>
                        {months.map((m, i) => {
                          const thisVal = thisYearData[i][metric.key];
                          const lastVal = lastYearData[i][metric.key];
                          const monthlyVar = calculateMonthlyVar(thisVal, lastVal);
                          const varPositive = parseFloat(monthlyVar) >= 0;
                          return (
                            <React.Fragment key={m}>
                              <td className="p-3 text-center border-l border-gray-100">
                                {metric.format(thisVal)}
                              </td>
                              <td className="p-3 text-center">
                                {metric.format(lastVal)}
                              </td>
                              <td className={`p-3 text-center font-semibold ${varPositive ? 'text-green-600 bg-green-50' : 'text-red-600 bg-red-50'}`}>
                                {monthlyVar}%
                              </td>
                            </React.Fragment>
                          );
                        })}
                        <td className="p-3 text-center font-semibold border-l border-gray-200">
                          {metric.format(totalThis)}
                        </td>
                        <td className="p-3 text-center font-semibold">
                          {metric.format(totalLast)}
                        </td>
                        <td className={`p-3 text-center font-semibold ${totalVarPositive ? 'text-green-600 bg-green-50' : 'text-red-600 bg-red-50'}`}>
                          {totalVar}%
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          {/* VAR SECTION */}
          <div className="bg-white shadow-sm border border-gray-100 rounded-lg p-6 space-y-4">
            <h2 className="text-xl font-semibold text-[#1B4D3E]">VAR (This Year vs Last Year)</h2>
            <div className="overflow-auto">
              <table className="min-w-full text-sm">
                <thead className="bg-gray-100 text-gray-600">
                  <tr>
                    <th className="p-3 text-left font-semibold w-40">Metric</th>
                    <th className="p-3 font-semibold text-center">VAR %</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 text-gray-800">
                  {varData.map((item, i) => {
                    const isPositive = parseFloat(item.value) >= 0;
                    return (
                      <tr
                        key={i}
                        className={`${isPositive ? 'bg-green-50' : 'bg-red-50'} hover:bg-gray-100 transition`}
                      >
                        <td className="p-3 font-semibold">{item.label}</td>
                        <td className={`p-3 text-center font-semibold ${isPositive ? 'text-green-600' : 'text-red-600'}`}>
                          {item.value}%
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}
