import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import IntroAnimation from './components/IntroAnimation';
import ChatBox from './components/ChatBox';
import Timeline from './components/Timeline';
import GamesPage from './components/GamesPage';
import FoundersFirstJourney from './components/FoundersFirstJourney';
import PageTitle from './components/PageTitle';
import EclarityDashboard from './components/EclarityDashboard';
import OnboardingWizard from './components/OnboardingWizard';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';

const App = () => {
  const [showChat, setShowChat] = useState(false);

  return (
    <Router>
      <PageTitle />
      <div className="min-h-screen bg-white">
        <Routes>
          <Route
            path="/"
            element={
              <div className="relative">
                <IntroAnimation onComplete={() => setShowChat(true)} />
                <ChatBox showChat={showChat} />
              </div>
            }
          />
          <Route
            path="/history"
            element={
              <>
                <Timeline />
                <ChatBox showChat={true} />
              </>
            }
          />
          <Route
            path="/projects"
            element={
              <>
                <div>Projects Coming Soon...</div>
                <ChatBox showChat={true} />
              </>
            }
          />
          <Route
            path="/games"
            element={
              <>
                <GamesPage />
                <ChatBox showChat={true} />
              </>
            }
          />
          <Route
            path="/games/founders-journey"
            element={
              <>
                <FoundersFirstJourney />
                <ChatBox showChat={true} />
              </>
            }
          />
          <Route
            path="/e-clarity"
            element={
              <>
                <EclarityDashboard />
                <ChatBox showChat={true} />
              </>
            }
          />
          <Route path="/onboarding" element={<OnboardingWizard />} />

          {/* Legal pages */}
          <Route path="/terms-and-conditions" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />

          {/* Login and Dashboard */}
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
